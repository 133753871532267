import React from "react";
import Discord from '../assets/Discord.jpg'
import EmailSupport from '../assets/EmailSupport.jpg'
import MainSite from '../assets/MainSite.jpg'
import RelayEducation from '../assets/RelayEducation.jpg'
import Telegram from '../assets/Telegram.jpg'
import Terms from '../assets/Terms.jpg'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Links = () => {
  return (
    <div className="links-container">
      <div className="links-section">
        <div className="section-title">
          <h2>Get <span className="section-title_bold">Started</span></h2>
          <div className="title-line"></div>
        </div>
        <div className="links-list">
          <a href="https://educ.ator.io" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={RelayEducation} className="link-image" alt="Relay Education" />
            <div className="link-text">
              <span className="link-description">Relay Education</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
          <a href="https://ator.io" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={MainSite} className="link-image" alt="Main Site" />
            <div className="link-text">
              <span className="link-description">Main Site</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
          <a href="https://educ.ator.io/resources/terms" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={Terms} className="link-image" alt="Terms" />
            <div className="link-text">
              <span className="link-description">Terms</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
        </div>
      </div>
      <div className="links-section">
        <div className="section-title">
          <h2>Get <span className="section-title_bold">Support</span></h2>
          <div className="title-line"></div>
        </div>
        <div className="links-list">
          <a href="https://discord.gg/ator" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={Discord} className="link-image" alt="Discord" />
            <div className="link-text">
              <span className="link-description">Discord</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
          <a href="https://t.me/atorsupportbot" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={Telegram} className="link-image" alt="Telegram" />
            <div className="link-text">
              <span className="link-description">Telegram</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
          <a href="mailto:support@ator.io" target="_blank" rel="noopener noreferrer" className="link-item">
            <img src={EmailSupport} className="link-image" alt="Email Support" />
            <div className="link-text">
              <span className="link-description">Email Support</span>
            </div>
            <OpenInNewIcon className="external-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Links;
