import React from "react";
import { useParams } from 'react-router-dom';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const SerialNumber = () => {
    const { serial } = useParams();
    const displaySerial = serial || '\u00A0'.repeat(10);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(displaySerial).then(() => {
          alert('Serial number copied to clipboard');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      };

    return (
        <div className="header-container">
            <h1 className="header-title">
                Welcome to your <span className="teal-color">Relay</span>
            </h1>
            <div className="serial-number-container">
                <span className="serial-number-title">Serial ID</span>
                <div className="serial-number-content">
                    <h3 
                    className="serial-number"
                    onClick={copyToClipboard}
                    >
                        {displaySerial}
                    </h3>
                    <ContentCopyIcon
                        className="copy-icon"
                        onClick={copyToClipboard()}
                    />
                </div>
            </div>
        </div>
    );
};

export default SerialNumber;
