import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SerialNumber from './components/SerialNumber';
import Links from './components/Links';

function App() {
    return (
        <Router>
            <div className='app__wrapper'>
                <div className="app-container">
                    <Routes>
                        <Route path="/:serial?" element={<SerialNumber />} />
                    </Routes>
                    <Links />
                </div>
            </div>
        </Router>
    );
}

export default App;
